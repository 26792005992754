import React from "react";

const SupplyComponent = ({ imageSrc, title, carryoutItems, stockpileItems, unit }) => {
  return (
    <div className="supply-component">
      <img src={imageSrc} alt={title} />
      <h4 className="axt-captionL">{title}</h4>
      <dl className="supply-items axt-captionM">
        <dt>持出品</dt>
        <dd>×{carryoutItems}{unit}</dd>
      </dl>
      <dl className="supply-items axt-captionM">
        <dt>備蓄品</dt>
        <dd>×{stockpileItems}{unit}</dd>
      </dl>
    </div>
  );
};

export default SupplyComponent;