import React from 'react';
import { Link } from 'react-router-dom';

function PrimaryButton({ label, to, onClick, shouldDisable, className }) {
  if(to) {
    return <Link to={to} className='primary-button axt-m'>{label}</Link>
  } else if(onClick){
    return <button className={`axt-m ${className || ''}`} onClick={onClick} disabled={shouldDisable}>{label}</button>
  };
}

export default PrimaryButton;
