import React from "react";
import minus from "../assets/img/minus.svg";
import plus from "../assets/img/plus.svg";

function CounterComponent({ label, count, onChange }) {
  return (
    <div className="counter">
      <h3 className="axt-m">{label}</h3>
      <div>
        <button onClick={() => count > 0 && onChange(count - 1)}>
          <img src={minus} alt="マイナス" />
        </button>
        <span className="axt-counter">{count}</span>
        <button onClick={() => onChange(count + 1)}>
          <img src={plus} alt="プラス" />
        </button>
      </div>
    </div>
  );
}

export default CounterComponent;
