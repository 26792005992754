// QuestionsData.js
import familyIcon from "../assets/img/family-icon.svg";
import babyIcon from "../assets/img/baby-icon.svg";
import seniorsIcon from "../assets/img/seniors-icon.svg";
import typeOfHouse from "../assets/img/type-of-house-icon.svg";
import ageOfHouse from "../assets/img/age-of-house-icon.svg";
import structureOfHouse from "../assets/img/structure-of-house-icon.svg";
import areaIcon from "../assets/img/area-icon.svg";
import riskIcon from "../assets/img/risk-icon.svg";


const questions = [
  {
    id: "1",
    text: () => (
      <>
        いま一緒に住んでいるご家族の<br /><span style={{color: '#E92752'}}>人数</span>を教えてください
      </>
    ),
    image: familyIcon,
    counterLabels: [
      {
        id: "q1_male",
        label: "男性",
      },
      {
        id: "q1_female",
        label: "女性",
      },
    ],
    isRequired: true,
    multipleSelect: false,
  },
  {
    id: "2",
    text: () => (
      <>
        いま一緒に住んでいるご家族で<br /><span style={{color: '#E92752'}}>以下の選択肢に当てはまる</span>人数を教えてください
      </>
    ),
    image: babyIcon,
    counterLabels: [
      {
        id: "q2_baby",
        label: "乳幼児（小学校入学前）",
      },
      {
        id: "q2_chilrden",
        label: "小学生",
      },
      {
        id: "q2_senior",
        label: "高齢者",
      },
    ],
    isRequired: false,
    multipleSelect: false,
  },
  {
    id: 3,
    text: () => (
      <>
        いま一緒に住んでいるご家族に<br /><span style={{color: '#E92752'}}>要介護者</span>はいますか？
      </>
    ),
    image: seniorsIcon,
    counterLabels: [
      {
        id: "q3_male_nc",
        label: "男性",
      },
      {
        id: "q3_female_nc",
        label: "女性",
      },
    ],
    isRequired: false,
    multipleSelect: false,
  },
  {
    id: 4,
    text: () => (
      <>
        いま住んでいる<span style={{color: '#E92752'}}>家の種類</span>を<br />教えてください
      </>
    ),
    image: typeOfHouse,
    selectorLabels: [
      {
        id: "q4_det",
        label: "戸建て",
      },
      {
        id: "q4_mn9u",
        label: "集合住宅（9階以下）",
      },
      {
        id: "q4_mn9o",
        label: "集合住宅（10階以上）",
      },
    ],
    isRequired: true,
    multipleSelect: false,
  },
  {
    id: 5,
    text: () => (
      <>
        いま住んでいる<span style={{color: '#E92752'}}>家の築年数</span>を<br />教えてください
      </>
    ),
    image: ageOfHouse,
    selectorLabels: [
      {
        id: "q5_ose",
        label: "1981年以前（旧耐震基準）",
      },
      {
        id: "q5_nse",
        label: "1981年以降（新耐震基準）",
      },
      {
        id: "q5_not_clear",
        label: "分からない",
      }
    ],
    isRequired: true,
    multipleSelect: false,
  },
  {
    id: 6,
    text: () => (
      <>
        いま住んでいる<span style={{color: '#E92752'}}>家の構造</span>を<br />教えてください
      </>
    ),
    image: structureOfHouse,
    selectorLabels: [
      {
        id: "q6_rc",
        label: "鉄筋系",
      },
      {
        id: "q6_wd",
        label: "木造系",
      },
      {
        id: "q6_not_clear",
        label: "分からない",
      }
    ],
    isRequired: true,
    multipleSelect: false,
  },
  {
    id: 7,
    text: () => (
      <>
        いま住んでいる家は<br /><span style={{color: '#E92752'}}>以下の選択肢に当てはまる</span><br />地形にありますか？
      </>
    ),
    image: areaIcon,
    selectorLabels: [
      {
        id: "q7_bs",
        label: "海沿い",
      },
      {
        id: "q7_bm",
        label: "山沿い",
      },
      {
        id: "q7_br",
        label: "川沿い",
      },
      {
        id: "q7_bc",
        label: "崖沿い",
      },
    ],
    isRequired: false,
    multipleSelect: true,
  },
  {
    id: 8,
    text: () => (
      <>
        寝室・リビングに<br /><span style={{color: '#E92752'}}>倒れる危険性のある</span>家具が<br />ひとつでもありますか？
      </>
    ),
    image: riskIcon,
    selectorLabels: [
      {
        id: "q8_y",
        label: "ある",
      },
      {
        id: "q8_n",
        label: "ない",
      },
    ],
    isRequired: true,
    multipleSelect: false,
  },
];

export default questions;
