import image1 from "../assets/img/getEmergencySupplies/1.jpeg"
import image2 from "../assets/img/getEmergencySupplies/2.jpeg"
import image3 from "../assets/img/getEmergencySupplies/3.jpeg"
import image4 from "../assets/img/getEmergencySupplies/4.jpeg"
import image5 from "../assets/img/getEmergencySupplies/5.jpeg"
import image6 from "../assets/img/getEmergencySupplies/6.jpeg"
import image7 from "../assets/img/getEmergencySupplies/7.png"
import image8 from "../assets/img/getEmergencySupplies/8.png"
import image9 from "../assets/img/getEmergencySupplies/9.jpeg"
import image10 from "../assets/img/getEmergencySupplies/10.jpeg"
import image11 from "../assets/img/getEmergencySupplies/11.jpeg"
import image12 from "../assets/img/getEmergencySupplies/12.jpeg"
import image13 from "../assets/img/getEmergencySupplies/13.jpeg"
import image14 from "../assets/img/getEmergencySupplies/14.jpeg"
import image15 from "../assets/img/getEmergencySupplies/15.jpeg"
import image16 from "../assets/img/getEmergencySupplies/16.jpeg"
import image17 from "../assets/img/getEmergencySupplies/17.jpeg"
import image18 from "../assets/img/getEmergencySupplies/18.jpeg"
import image19 from "../assets/img/getEmergencySupplies/19.jpeg"
import image20 from "../assets/img/getEmergencySupplies/20.jpeg"
import image21 from "../assets/img/getEmergencySupplies/21.jpeg"
import image22 from "../assets/img/getEmergencySupplies/22.jpeg"
import image23 from "../assets/img/getEmergencySupplies/23.jpeg"
import image24 from "../assets/img/getEmergencySupplies/24.jpeg"
import image25 from "../assets/img/getEmergencySupplies/25.jpeg"
import image26 from "../assets/img/getEmergencySupplies/26.jpeg"
import image27 from "../assets/img/getEmergencySupplies/27.jpeg"
import image28 from "../assets/img/getEmergencySupplies/28.jpeg"
import image29 from "../assets/img/getEmergencySupplies/29.jpeg"
import image30 from "../assets/img/getEmergencySupplies/30.jpeg"
import image31 from "../assets/img/getEmergencySupplies/31.jpeg"
import image32 from "../assets/img/getEmergencySupplies/32.jpeg"
import image33 from "../assets/img/getEmergencySupplies/33.jpeg"
import image34 from "../assets/img/getEmergencySupplies/34.jpeg"
import image35 from "../assets/img/getEmergencySupplies/35.jpeg"
import image36 from "../assets/img/getEmergencySupplies/36.jpeg"
import image37 from "../assets/img/getEmergencySupplies/37.jpeg"
import image38 from "../assets/img/getEmergencySupplies/38.jpeg"
import image39 from "../assets/img/getEmergencySupplies/39.jpeg"
import image40 from "../assets/img/getEmergencySupplies/40.jpeg"
import image41 from "../assets/img/getEmergencySupplies/41.jpeg"
import image42 from "../assets/img/getEmergencySupplies/42.jpeg"



export default function getEmergencySupplies(answers) {
  const maleCount = parseInt(answers.q1_male, 10) || 0;
  const femaleCount = parseInt(answers.q1_female, 10) || 0;
  const totalCount = maleCount + femaleCount;

  function calculateCarryout(item) {
    return totalCount * item.carryoutRequirement;
  }

  function calculateStockpile(item) {
    return totalCount * item.stockpileRequirement;
  }

  return [
    {
      title: 'ヘルメット',
      imageSrc: image1,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    },{
      title: '懐中電灯',
      imageSrc: image2,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    },{
      title: '携帯ラジオ',
      imageSrc: image3,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    },{
      title: 'スニーカー',
      imageSrc: image4,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '足',
      evaluator: () => {
        return true;
      },
    },{
      title: 'モバイルバッテリー',
      imageSrc: image5,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    },{
      title: '作業用手袋',
      imageSrc: image6,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '双',
      evaluator: () => {
        return true;
      },
    },{
      title: 'アルミブランケット',
      imageSrc: image7,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        return true;
      },
    },{
      title: '携帯トイレ',
      imageSrc: image8,
      carryoutRequirement: 5,
      stockpileRequirement: 35,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '回分',
      evaluator: () => {
        return true;
      },
    },{
      title: '生理用品',
      imageSrc: image9,
      carryoutRequirement: 5,
      stockpileRequirement: 35,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: (answers) => {
        if (answers.q1_female >= 1) return true;
        else return false;
      },
    },{
      title: 'トイレットペーパー',
      imageSrc: image10,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '巻',
      evaluator: () => {
        return true;
      },
    },{
      title: 'ラップ',
      imageSrc: image11,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '巻',
      evaluator: () => {
        return true;
      },
    },{
      title: '水ポリタンク',
      imageSrc: image12,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    },{
      title: '歯ブラシ・歯磨き・洗口液',
      imageSrc: image13,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '本',
      evaluator: () => {
        return true;
      },
    },{
      title: '紙おむつ',
      imageSrc: image14,
      carryoutRequirement: 10,
      stockpileRequirement: 70,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        if (answers.q2_baby >= 1) return true;
        else return false;
      },
    },{
      title: '使い捨ておしりふき',
      imageSrc: image15,
      carryoutRequirement: 10,
      stockpileRequirement: 70,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        if (answers.q2_baby >= 1) return true;
        else return false;
      },
    },{
      title: '入れ歯',
      imageSrc: image16,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        if (answers.q2_senior >= 1) return true;
        else return false;
      },
    },{
      title: '大人用紙おむつ',
      imageSrc: image14,
      carryoutRequirement: 5,
      stockpileRequirement: 35,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        if (answers.q2_senior >= 1) return true;
        else return false;
      },
    },{
      title: 'ばんそうこう・包帯',
      imageSrc: image17,
      carryoutRequirement: 10,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    },{
      title: '常備薬',
      imageSrc: image18,
      carryoutRequirement: 7,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '日分',
      evaluator: () => {
        return true;
      },
    },{
      title: '持病薬',
      imageSrc: image18,
      carryoutRequirement: 7,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '日分',
      evaluator: () => {
        return true;
      },
    },{
      title: '処方箋のコピー',
      imageSrc: image19,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        return true;
      },
    },{
      title: '飲料水',
      imageSrc: image20,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: 'リットル',
      evaluator: () => {
        return true;
      },
    },{
      title: '備蓄飲料水',
      imageSrc: image20,
      carryoutRequirement: 0,
      stockpileRequirement: 21,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: 'リットル',
      evaluator: () => {
        return true;
      },
    },{
      title: '調理不要の備蓄食料',
      imageSrc: image21,
      carryoutRequirement: 21,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '食',
      evaluator: () => {
        return true;
      },
    },{
      title: '調理不要の介護食',
      imageSrc: image21,
      carryoutRequirement: 3,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '食',
      evaluator: () => {
        if (answers.q3_male_nc >= 1 || answers.q3_female_nc >= 1) return true;
        else return false;
      },
    },{
      title: '介護食',
      imageSrc: image21,
      carryoutRequirement: 0,
      stockpileRequirement: 21,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '食',
      evaluator: () => {
        if (answers.q3_male_nc >= 1 || answers.q3_female_nc >= 1) return true;
        else return false;
      },
    },{
      title: '調理不要の離乳食・液体ミルク',
      imageSrc: image22,
      carryoutRequirement: 5,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '回分',
      evaluator: () => {
        if (answers.q2_baby >= 1) return true;
        else return false;
      },
    },{
      title: '離乳食・粉ミルク・液体ミルク',
      imageSrc: image22,
      carryoutRequirement: 0,
      stockpileRequirement: 35,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '回分',
      evaluator: () => {
        if (answers.q2_baby >= 1) return true;
        else return false;
      },
    },{
      title: '加熱調理器具',
      imageSrc: image23,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '台',
      evaluator: () => {
        if (answers.q2_baby >= 1) return true;
        else return false;
      },
    },{
      title: '雨合羽',
      imageSrc: image24,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '着',
      evaluator: () => {
        return true;
      },
    },{
      title: '現金',
      imageSrc: image25,
      carryoutRequirement: 7,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '日分',
      evaluator: () => {
        return true;
      },
    },{
      title: '10円硬貨',
      imageSrc: image26,
      carryoutRequirement: 10,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        return true;
      },
    },{
      title: '健康保険証、またはそのコピー',
      imageSrc: image27,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        return true;
      },
    },{
      title: '身分証明書、またはそのコピー',
      imageSrc: image27,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        return true;
      },
    },{
      title: 'ライター・マッチ',
      imageSrc: image28,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '箱',
      evaluator: () => {
        return true;
      },
    },{
      title: '万能ナイフ',
      imageSrc: image29,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '本',
      evaluator: () => {
        return true;
      },
    },{
      title: '筆記用具・メモ帳',
      imageSrc: image30,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: 'セット',
      evaluator: () => {
        return true;
      },
    },{
      title: 'ガスコンロ',
      imageSrc: image23,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '台',
      evaluator: () => {
        return true;
      },
    },{
      title: 'スリッパ',
      imageSrc: image31,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '足',
      evaluator: () => {
        return true;
      },
    },{
      title: 'マスク',
      imageSrc: image32,
      carryoutRequirement: 1,
      stockpileRequirement: 7,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        return true;
      },
    },{
      title: '携帯食',
      imageSrc: image21,
      carryoutRequirement: 3,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '食',
      evaluator: () => {
        return true;
      },
    },{
      title: '下着',
      imageSrc: image33,
      carryoutRequirement: 0,
      stockpileRequirement: 7,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '着',
      evaluator: () => {
        return true;
      },
    },{
      title: '靴下',
      imageSrc: image34,
      carryoutRequirement: 0,
      stockpileRequirement: 7,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '着',
      evaluator: () => {
        return true;
      },
    },{
      title: '印鑑',
      imageSrc: image35,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '本',
      evaluator: () => {
        return true;
      },
    },{
      title: '通帳、またはそのコピー',
      imageSrc: image36,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        return true;
      },
    },{
      title: '予備乾電池',
      imageSrc: image37,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: 'セット',
      evaluator: () => {
        return true;
      },
    },{
      title: 'ティッシュペーパー',
      imageSrc: image38,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    },{
      title: 'タオル',
      imageSrc: image39,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '本',
      evaluator: () => {
        return true;
      },
    },{
      title: 'ビニル袋',
      imageSrc: image40,
      carryoutRequirement: 1,
      stockpileRequirement: 0,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '枚',
      evaluator: () => {
        return true;
      },
    },{
      title: 'アルコール消毒液',
      imageSrc: image41,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '本',
      evaluator: () => {
        return true;
      },
    },{
      title: 'ハンドソープ・石けん',
      imageSrc: image41,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    },{
      title: '除菌シート',
      imageSrc: image15,
      carryoutRequirement: 1,
      stockpileRequirement: 7,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '袋',
      evaluator: () => {
        return true;
      },
    },{
      title: '体温計',
      imageSrc: image42,
      carryoutRequirement: 0,
      stockpileRequirement: 1,
      calculateEmergencyCarryoutItems: calculateCarryout,
      calculateStockpileItemsQuantity: calculateStockpile,
      unit: '個',
      evaluator: () => {
        return true;
      },
    }
  ];
}
