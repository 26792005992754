import React, { useState, useEffect } from 'react';
import PreparationList from '../components/PreparationList';
import preparations from '../data/Preparation';
import { useSearchParams } from "react-router-dom";
import resultImage from "../assets/img/result.svg";
import { Link } from 'react-router-dom';
import mustPreparations from '../data/MustPreparations';
import MustPreparationList from '../components/MustPreparationList';
import getEmergencySupplies from '../data/EmergencySupplies';
import SuppliesList from '../components/SuppliesList';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';


function ResultPage() {
  const [searchParams] = useSearchParams();

  const answers = {};
  for (let [key, value] of searchParams) {
    answers[key] = value;
  }

  const filteredPreparations = preparations.filter((prep) => prep.evaluator(answers));
  const filteredMustPreparations = mustPreparations.filter((prep) => prep.evaluator(answers));
  const emergencySupplies = getEmergencySupplies(answers);
  const filteredEmergecySupplies = emergencySupplies.filter((prep) => prep.evaluator(answers));
  const [showAllPreparations, setShowAllPreparations] = useState(false);
  const displayedPreparations = showAllPreparations ? filteredPreparations : filteredPreparations.slice(0, 6);
  const [showAllSupplies, setShowAllSupplies] = useState(false);
  const displayedSupplies = showAllSupplies ? filteredEmergecySupplies : filteredEmergecySupplies.slice(0, 10);

  const location = useLocation();
  const shareUrl = () => {
    const currentUrl = encodeURIComponent(window.location.origin + location.pathname + location.search);
    return `https://social-plugins.line.me/lineit/share?url=${currentUrl}`;
  };
  

  return (
    <div className='result-page wrapper'>
      <Helmet>
        <title>診察結果 | みんなの防災クリニック</title>
        <meta property="og:title" content="診察結果 | みんなの防災クリニック" />
        <meta property="og:description" content="あなたの防災対策を診察します | みんなの防災クリニック" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <img src={resultImage} alt="あなたの診察結果" />
      <h1 className='axt-h1'>あなたの診察結果</h1>
      {filteredMustPreparations.length > 0 && (
        <div className='must-preparation-container'>
          <div className='preparation-container'>
            <h2 className='axt-h2'><span className='axt-captionM'>これは絶対必要</span>まずは必須の準備から</h2>
          </div>
          <MustPreparationList dataList={filteredMustPreparations} />
        </div>
      )}
      <div className='preparation-container' style={{ marginBlockStart: '60px' }}>
        <h2 className='axt-h2'><span className='axt-captionM'>いざという時に備えよう！</span>あなただけの準備リスト</h2>
      </div>
      <h3 className='axt-captionL result-list-title'>必要な準備と対策</h3>
      <PreparationList dataList={displayedPreparations} />
      {!showAllPreparations && (
        <button className='show-all-contents axt-m' onClick={() => setShowAllPreparations(true)}>
          全て表示する
        </button>
      )}
      <a href='https://forms.gle/6BwNfs3oysLYihsg9' target="_blank" rel="noopener noreferrer" className='questionnaire-button'>
        <button className='axt-m' style={{ marginBlockStart: '8px' }}>
          アンケートに回答
        </button>
      </a>
      <h3 className='axt-captionL result-list-title' style={{ marginBlockStart: '40px' }}>必要備品リスト</h3>
      <SuppliesList dataList={displayedSupplies} />
      {!showAllSupplies && (
        <button className='show-all-contents axt-m' onClick={() => setShowAllSupplies(true)}>
          全て表示する
        </button>
      )}
      <div className='confetti-container'>
        <p className='axt-body'>お疲れ様でした！</p>
        <p className='axt-h1'>万が一の災害のために<br />しっかり備えておきましょう！</p>
        <p className='axt-captionM'>リストを忘れないように、<br />お使いのスマホでURLの保存、<br />またはスクリーンショットを撮ることをお勧めします。</p>
      </div>
      <a href='https://forms.gle/6BwNfs3oysLYihsg9' target="_blank" rel="noopener noreferrer" className='questionnaire-button'>
        <button className='axt-m'>
          アンケートに回答
        </button>
      </a>
      <a href={shareUrl()} target="_blank" rel="noopener noreferrer" className='line-it-button'>
        <button className='axt-m'>
          LINEでシェア
        </button>
      </a>
      <Link to='/'>
        <button className='redo-button axt-captionM'>やり直す</button>
      </Link>
    </div>
  );
}


export default ResultPage;
