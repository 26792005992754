import React from 'react';
import './assets/main.scss'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from './containers/LandingPage';
import QuestionPage from './containers/QuestionPage';
import ResultPage from './containers/ResultPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={< LandingPage />} />
        <Route path={'/questions'} element={< QuestionPage />} />
        <Route path={'/result'} element={< ResultPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
