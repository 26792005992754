import React from "react";
import ArrowLeft from "../assets/img/arrow-left.svg"
import { Link } from "react-router-dom";

function BackButton({ to, label, onClick }) {
  if (to) {
    return (
      <Link to={to} className='back-button axt-m'>
        <img src={ArrowLeft} alt={label} />
        <button className="axt-body">{label}</button>
      </Link>
    );
  } else if (onClick) {
    return (
      <div className="back-button">
        <img src={ArrowLeft} alt={label} />
        <button className="axt-body" onClick={onClick}>{label}</button>
      </div>
    );
  }
};


export default BackButton;