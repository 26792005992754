import image1 from "../assets/img/mustPreparations/1.jpeg"
import image2 from "../assets/img/mustPreparations/2.jpeg"
import image3 from "../assets/img/mustPreparations/3.jpeg"
import image4 from "../assets/img/mustPreparations/4.jpeg"
import image5 from "../assets/img/mustPreparations/5.jpeg"
import image6 from "../assets/img/mustPreparations/6.jpeg"
import image7 from "../assets/img/mustPreparations/7.jpeg"


const mustPreparations = [
  {
    title: '地域の災害の確認',
    description: '市町村からハザードマップを手に入れて、災害が起こったらどこに避難するかを知りましょう。',
    imageSrc: image1,
    linkHref: 'https://www.quake-learning.pref.aichi.jp/?page_id=109',
    evaluator: (answer) => {
      if (answer.q4_det >= 1 || answer.q4_mn9u >= 1) return true;
      else return false;
    }    
  },{
    title: '耐震診断受診',
    description: '自宅建物が地震に耐えられるかどうかを知りましょう。',
    imageSrc: image2,
    linkHref: 'https://www.pref.aichi.jp/soshiki/jutakukeikaku/0000025466.html',
    evaluator:(answer) => {
      if (answer.q5_nse >= 1 && answer.q6_wd >= 1) return true;
      else return false;
    }
  },{
    title: '家具等転倒防止(寝室)',
    description: '寝室の家具の配置を見直し、固定し、倒れてくる家具でけがをしないようにしましょう。',
    imageSrc: image3,
    linkHref: 'https://www.pref.aichi.jp/bousai/kagukotei/kagukotei.pdf',
    evaluator:(answer) => {
      if (answer.q8_y >= 1) return true;
      else return false;
    }
  },{
    title: '発災時行動(地震)',
    description: '地震・津波・大雨・暴風・火災が起きた時に、身を守る行動や素早い避難ができるようにしましょう。',
    imageSrc: image4,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387936.pdf',
    evaluator:(answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    }
  },{
    title: '発災時行動(土砂災害)',
    description: '地震・津波・大雨・暴風・火災が起きた時に、身を守る行動や素早い避難ができるようにしましょう。',
    imageSrc: image5,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387962.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1 && (answer.q7_bm || answer.q7_bc)) return true;
      else return false;
    }    
  },{
    title: '安否確認',
    description: '災害時に、家族で安否確認ができるようにしましょう。',
    imageSrc: image6,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },{
    title: '家族会議(避難タイミング)',
    description: '防災家族会議を開いて、家族全員が避難できるよう決めておきましょう。',
    imageSrc: image7,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },
]



export default mustPreparations;