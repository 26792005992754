import React from 'react';

function RadioButtonComponent({ id, label, selectedId, onChange, type }) {
  const handleOnChange = () => {
    onChange(id);
  };

  return (
    <div className="radio-button-component">
      <label className='axt-selector'>
      <input
        type={type}
        name='questions'
        value={id}
        checked={selectedId === '1'}
        onChange={handleOnChange}
      />
        <span className='checkmark' />
        {label}
      </label>
    </div>
  );
}


export default RadioButtonComponent;
