import React from 'react';
import PreparationComponent from './PreparationComponent';

const MustPreparationList = ({ dataList }) => {
  return (
    <div className="preparation-component-list">
      {dataList.map((data, index) => (
        <PreparationComponent
          key={index}
          imageSrc={data.imageSrc}
          title={data.title}
          description={data.description}
          linkHref={data.linkHref}
        />
      ))}
    </div>
  );
}

export default MustPreparationList;
