// PrepareationData.js

import image1 from "../assets/img/prepareations/1.jpeg"
import image2 from "../assets/img/prepareations/2.jpeg"
import image3 from "../assets/img/prepareations/3.jpeg"
import image4 from "../assets/img/prepareations/4.jpeg"
import image5 from "../assets/img/prepareations/5.jpeg"
import image6 from "../assets/img/prepareations/6.jpeg"
import image7 from "../assets/img/prepareations/7.jpeg"
import image8 from "../assets/img/prepareations/8.jpeg"
import image9 from "../assets/img/prepareations/9.jpeg"
import image10 from "../assets/img/prepareations/10.jpeg"
import image11 from "../assets/img/prepareations/11.jpeg"
import image12 from "../assets/img/prepareations/12.jpeg"
import image13 from "../assets/img/prepareations/13.jpeg"
import image14 from "../assets/img/prepareations/14.jpeg"
import image15 from "../assets/img/prepareations/15.jpeg"
import image16 from "../assets/img/prepareations/16.jpeg"
import image17 from "../assets/img/prepareations/17.jpeg"
import image18 from "../assets/img/prepareations/18.jpeg"
import image19 from "../assets/img/prepareations/19.jpeg"
import image20 from "../assets/img/prepareations/20.jpeg"
import image21 from "../assets/img/prepareations/21.jpeg"
import image22 from "../assets/img/prepareations/22.jpeg"
import image23 from "../assets/img/prepareations/23.jpeg"
import image24 from "../assets/img/prepareations/24.jpeg"
import image25 from "../assets/img/prepareations/25.jpeg"


const prepareations = [
  {
    title: 'ハザードマップの入手',
    description: '市町村からハザードマップを手に入れて、災害が起こったらどこに避難するかを知りましょう。',
    imageSrc: image1,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator: () => {
      return true;
    }    
  },{
    title: '避難所等の確認',
    description: '市町村からハザードマップを手に入れて、災害が起こったらどこに避難するかを知りましょう。',
    imageSrc: image2,
    linkHref: 'https://maps.pref.aichi.jp/',
    evaluator: (answer) => {
      if (answer.q4_det >= 1 || answer.q4_mn9u >= 1) return true;
      else return false;
    }
  },{
    title: '自宅耐震性の認知',
    description: '自宅建物が地震に耐えられるかどうかを知りましょう。',
    imageSrc: image3,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator:(answer) => {
      if (answer.q5_nse >= 1 && answer.q6_wd >= 1) return true;
      else return false;
    }
  },{
    title: '耐震工事',
    description: '耐震工事をしましょう。',
    imageSrc: image4,
    linkHref: 'https://www.pref.aichi.jp/soshiki/jutakukeikaku/0000025466.html',
    evaluator:() => {
      return true;
    }
  },{
    title: '家具等転倒防止(寝室以外)',
    description: '家具の配置を見直し、固定し、倒れてくる家具でけがをしないようにしましょう。',
    imageSrc: image5,
    linkHref: 'https://www.pref.aichi.jp/bousai/kagukotei/kagukotei.pdf',
    evaluator:(answer) => {
      if (answer.q8_y >= 1) return true;
      else return false;
    }
  },{
    title: 'ガラス飛散防止フィルム',
    description: '寝室の家具の配置を見直し、固定し、倒れてくる家具でけがをしないようにしましょう。',
    imageSrc: image6,
    linkHref: 'https://www.pref.aichi.jp/bousai/kagukotei/kagukotei.pdf',
    evaluator:(answer) => {
      if (answer.q8_y >= 1) return true;
      else return false;
    }
  },{
    title: '発災時行動(地震・長周期)',
    description: '地震・津波・大雨・暴風・火災が起きた時に、身を守る行動や素早い避難ができるようにしましょう。',
    imageSrc: image7,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387936.pdf',
    evaluator:(answer) => {
      if (answer.q2_chilrden >= 1 && answer.q4_mn9o >= 1) return true;
      else return false;
    }
  },{
    title: '発災時行動(津波)',
    description: '地震・津波・大雨・暴風・火災が起きた時に、身を守る行動や素早い避難ができるようにしましょう。',
    imageSrc: image8,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387936.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1 && (answer.q7_bs || answer.q7_br)) return true;
      else return false;
    }    
  },{
    title: '発災時行動(風水害)',
    description: '地震・津波・大雨・暴風・火災が起きた時に、身を守る行動や素早い避難ができるようにしましょう。',
    imageSrc: image9,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387997.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1 && (answer.q7_bs || answer.q7_br)) return true;
      else return false;
    }    
  },{
    title: '発災時行動(火災)',
    description: '地震・津波・大雨・暴風・火災が起きた時に、身を守る行動や素早い避難ができるようにしましょう。',
    imageSrc: image10,
    linkHref: '',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },{
    title: '災害用伝言ダイヤル',
    description: '災害時に、家族で安否確認ができるようにしましょう。',
    imageSrc: image11,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387941.pdf',
    evaluator:() => {
      return true;
    }
  },{
    title: '災害用伝言板',
    description: '災害時に、家族で安否確認ができるようにしましょう。',
    imageSrc: image12,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387941.pdf',
    evaluator:() => {
      return true;
    }
  },{
    title: '家族会議(避難場所)',
    description: '防災家族会議を開いて、家族全員が避難できるよう決めておきましょう。',
    imageSrc: image13,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },{
    title: '家族会議(避難経路)',
    description: '防災家族会議を開いて、家族全員が避難できるよう決めておきましょう。',
    imageSrc: image13,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },{
    title: '家族会議(避難訓練)',
    description: '防災家族会議を開いて、家族全員が避難できるよう決めておきましょう。',
    imageSrc: image13,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },{
    title: '家族会議(避難危険回避)',
    description: '防災家族会議を開いて、家族全員が避難できるよう決めておきましょう。',
    imageSrc: image13,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },{
    title: '非常持出品',
    description: '避難の際に持ち出すものを準備しましょう。',
    imageSrc: image14,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387941.pdf',
    evaluator:() => {
      return true;
    }
  },{
    title: '防災情報入手',
    description: 'どこでも、災害に必要な情報を得る方法を聞く・わかるようにしましょう。',
    imageSrc: image15,
    linkHref: 'https://www.pref.aichi.jp/soshiki/shobohoan/kanshin-breaker01.html',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },{
    title: '帰宅支援',
    description: '自宅外にいても、身を守ることができるようにしましょう。',
    imageSrc: image16,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387936.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1) return true;
      else return false;
    } 
  },{
    title: '土砂災害対策(事前避難)',
    description: '大雨・暴風に対して事前の備えをしましょう。',
    imageSrc: image17,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387995.pdf',
    evaluator: (answer) => {
      if (answer.q2_chilrden >= 1 && (answer.q7_bm || answer.q7_bc)) return true;
      else return false;
    }  
  },{
    title: '備蓄品',
    description: '避難生活に必要なものを備蓄しましょう。',
    imageSrc: image14,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387941.pdf',
    evaluator:(answer) => {
      if (answer.q8_n >= 1) return true;
      else return false;
    }
  },{
    title: '避難情報',
    description: 'どこでも、災害に必要な情報を得る方法を聞く・わかるようにしましょう。',
    imageSrc: image15,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387997.pdf',
    evaluator:() => {
      return true;
    }
  },{
    title: '防災気象情報',
    description: 'どこでも、災害に必要な情報を得る方法を聞く・わかるようにしましょう。',
    imageSrc: image18,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387997.pdf',
    evaluator:() => {
      return true;
    }
  },{
    title: '避難前行動',
    description: '火災などの二次災害が起きないようにしましょう。',
    imageSrc: image19,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator:() => {
      return true;
    }
  },{
    title: '感震ブレーカー',
    description: '火災などの二次災害が起きないようにしましょう。',
    imageSrc: image20,
    linkHref: 'https://www.pref.aichi.jp/soshiki/shobohoan/kanshin-breaker01.html',
    evaluator:() => {
      return true;
    }
  },{
    title: '火災報知器',
    description: '火災などの二次災害が起きないようにしましょう。',
    imageSrc: image21,
    linkHref: 'https://www.pref.aichi.jp/bousai/shobo/index.html',
    evaluator:() => {
      return true;
    }
  },{
    title: '地震保険',
    description: '事前に保険に加入しておきましょう。',
    imageSrc: image22,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387934.pdf',
    evaluator:() => {
      return true;
    }
  },{
    title: '火災保険',
    description: '事前に保険に加入しておきましょう。',
    imageSrc: image22,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387995.pdf',
    evaluator:() => {
      return true;
    }
  },{
    title: '要配慮者認知',
    description: '身の回りの人たちの手助けをしましょう。',
    imageSrc: image23,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387937.pdf',
    evaluator:(answer) => {
      if (answer.q2_baby >= 1 || answer.q2_seniors >= 1 || answer.q3_male >= 1 || answer.q3_female >= 1) return true;
      else return false;
    }
  },{
    title: '要配慮者との関係',
    description: '身の回りの人たちの手助けをしましょう。',
    imageSrc: image23,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387937.pdf',
    evaluator:(answer) => {
      if (answer.q2_baby >= 1 || answer.q2_seniors >= 1 || answer.q3_male >= 1 || answer.q3_female >= 1) return true;
      else return false;
    }
  },{
    title: '要配慮者対応',
    description: '身の回りの人たちの手助けをしましょう。',
    imageSrc: image23,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387937.pdf',
    evaluator:(answer) => {
      if (answer.q2_baby >= 1 || answer.q2_seniors >= 1 || answer.q3_male >= 1 || answer.q3_female >= 1) return true;
      else return false;
    }
  },{
    title: '防災訓練',
    description: '身の回りの人たちと助け合いましょう。',
    imageSrc: image24,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387939.pdf',
    evaluator:(answer) => {
      return true;
    }
  },{
    title: '風水害対策(防水)',
    description: '大雨・暴風に対して事前の備えをしましょう。',
    imageSrc: image25,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387995.pdf',
    evaluator:(answer) => {
      if (answer.q4_det >= 1 && answer.q7_br >= 1) return true;
      else return false;
    }
  },{
    title: '風水害対策(飛散防止)',
    description: '大雨・暴風に対して事前の備えをしましょう。',
    imageSrc: image25,
    linkHref: 'https://www.pref.aichi.jp/uploaded/attachment/387995.pdf',
    evaluator:(answer) => {
      return true;
    }
  }
]

export default prepareations;


