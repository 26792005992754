import React from "react";
import SupplyComponent from "./SupplyComponent";

function SuppliesList ({ dataList }) {
  return (
    <div className="supplies-list">
      {dataList.map((data, index) => (
        <SupplyComponent
          key={index}
          imageSrc={data.imageSrc}
          title={data.title}
          carryoutItems={data.calculateEmergencyCarryoutItems(data)}
          stockpileItems={data.calculateStockpileItemsQuantity(data)}
          unit={data.unit}
        />
      ))}
    </div>
  );
};

export default SuppliesList;