import RadioButtonComponent from './RadioButtonComponent';

function MultipleRadioButtonComponent({ labels, answer, onChange, multipleSelect }) {
  const handleRadioChange = (id) => {
    const updatedAnswer = { ...answer };
  
    if (multipleSelect) {
      updatedAnswer[id] = answer[id] === '1' ? '0' : '1';
    } else {
      labels.forEach(({ id: labelId }) => {
        updatedAnswer[labelId] = labelId === id ? '1' : '0';
      });
    }
  
    onChange(updatedAnswer);
  };

  return (
    <div className="multiple-radio">
      {labels.map(({ id, label }) => (
        <RadioButtonComponent
          key={id}
          id={id}
          label={label}
          selectedId={answer[id]}
          onChange={handleRadioChange}
          type={multipleSelect ? 'checkbox' : 'radio'}
      />
      ))}
    </div>
  );
}


export default MultipleRadioButtonComponent;
