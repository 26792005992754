import React from 'react';
import CounterComponent from './CounterComponent';

function MultipleCounterComponent({ labels, answer, onChange }) {
  return (
    <div className="multiple-counter">
      {labels.map(({id, label}, index) => (
        <CounterComponent
          key={id}    
          label={label} 
          count={answer[id]} 
          onChange={(count) => {
            onChange({
              ...answer,
              [id]: count
            })
        }} />
      ))}
    </div>
  );
}

export default MultipleCounterComponent;
