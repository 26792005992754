import React from 'react';
import newWindowIcon from "../assets/img/new-window.svg";

const PreparationComponent = ({ imageSrc, title, description, linkHref }) => {
  return (
    <div className="preparation-component">
      <img src={imageSrc} alt={title} />
      <div>
        <h4 className='axt-captionL'>{title}</h4>
        <p className='axt-captionS' style={{ marginBlockEnd: '4px' }}>{description}</p>
        <div className='text-button'>
          <a href={linkHref} className='axt-txtBtn' target="_blank" rel="noopener noreferrer">詳細はこちら</a>
          <img src={newWindowIcon} alt="リンク先へ" />
        </div>
      </div>
    </div>
  );
}

export default PreparationComponent;
