import React, { useState, useEffect } from "react";
import Indicator from "../components/IndicatorComponent";
import QuestionText from "../components/QuestionTextComponent";
import PrimaryButton from "../components/PrimaryButtonComponent";
import MultipleCounterComponent from "../components/MultipleCounterComponent";
import MultipleRadioButtonComponent from "../components/MultipleRadioButtonComponent";
import questions from "../data/QuestionsData";
import { useNavigate, useSearchParams } from "react-router-dom";
import stethoscopeIcon from "../assets/img/stethoscope-icon.svg";
import { Helmet } from 'react-helmet';
import BackButton from "../components/BackButtonComponent";

function QuestionPage() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const navigate = useNavigate();
  const initialAnswer = () => {
    if (questions[0].counterLabels) {
      return Object.fromEntries(
        questions[0].counterLabels.map((l) => [l.id, 0])
      );
    } else if (questions[0].selectorLabels) {
      return Object.fromEntries(
        questions[0].selectorLabels.map((l) => [l.id, 0])
      );
    }
    return {};
  };

  const [currentAnswer, setCurrentAnswer] = useState(initialAnswer);
  const [urlParams, setUrlParams] = useSearchParams();

  const handleNextQuestion = () => {
    const params = {};
    urlParams.forEach((v, k) => {
      params[k] = v;
    });
    const allAnswers = {
      ...params,
      ...currentAnswer,
    };
    if (currentQuestionIndex < questions.length) {
      allAnswers.qid = currentQuestionIndex + 1;
      console.log("allAnswers:", allAnswers);
      setUrlParams(allAnswers);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const { qid, ...answers } = allAnswers;
      const paramText = Object.entries(answers)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      navigate(`/result?${paramText}`);
    }
  };

  const resetCurrentAnswerForQuestion = (question) => {
    if (question.counterLabels) {
      return Object.fromEntries(question.counterLabels.map((l) => [l.id, 0]));
    } else if (question.selectorLabels) {
      return Object.fromEntries(question.selectorLabels.map((l) => [l.id, 0]));
    }
    return {};
  };

  const currentQuestion = questions[currentQuestionIndex - 1];

  useEffect(() => {
    const newCurrentAnswer = resetCurrentAnswerForQuestion(currentQuestion);
    setCurrentAnswer(newCurrentAnswer);
  }, [currentQuestionIndex]);

  useEffect(() => {
    console.log("currentAnswer after radio button change:", currentAnswer);
  }, [currentAnswer]);

  const shouldDisableButton = () => {
    if (currentQuestion.isRequired) {
      if (currentQuestion.counterLabels) {
        // カウンターの場合
        const isAnswered = Object.values(currentAnswer).some(
          (value) => parseInt(value, 10) >= 1
        );
        const result = !isAnswered;
        console.log("Disable button for counter?", result);
        return !isAnswered;
      } else if (currentQuestion.selectorLabels) {
        // ラジオボタンの場合
        const isAnswered = Object.values(currentAnswer).some(
          (value) => value === "1"
        );
        const result = !isAnswered;
        console.log("Disable button for radio?", result);
        return !isAnswered;
      }
    }
    console.log("Disable button by default?", false);
    return false;
  };

  const [isButtonFixed, setIsButtonFixed] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      const contentHeight = document.querySelector('.content-height').offsetHeight;
      const viewportHeight = window.innerHeight;
      const heightDifference = viewportHeight - contentHeight;
      setIsButtonFixed(heightDifference > 112);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentQuestionIndex]);

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 1) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  

  return (
    <div className="question-page wrapper">
      <Helmet>
        <title>みんなの防災クリニック</title>
        <meta property="og:title" content="みんなの防災クリニック" />
        <meta property="og:description" content="あなたの防災対策を診察します | みんなの防災クリニック" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="content-height">
        <Indicator current={currentQuestionIndex} total={questions.length} />
        <BackButton label="戻る" {...(currentQuestionIndex === 1 ? {to: '/'} : {onClick: handlePreviousQuestion})} />
        <div className="question-count">
          <img src={stethoscopeIcon} alt="診察" />
          <p className="axt-captionL">診察{currentQuestionIndex}</p>
        </div>
        <QuestionText text={currentQuestion.text()} />
        <img
          src={currentQuestion.image}
          alt={currentQuestion.text}
          className="question-image"
        />

        {currentQuestion.counterLabels && (
          <MultipleCounterComponent
            labels={currentQuestion.counterLabels}
            answer={currentAnswer}
            onChange={(answer) => setCurrentAnswer(answer)}
          />
        )}

        {currentQuestion.selectorLabels && (
          <MultipleRadioButtonComponent
            labels={currentQuestion.selectorLabels}
            answer={currentAnswer}
            onChange={(answer) => setCurrentAnswer(answer)}
            multipleSelect={currentQuestion.multipleSelect}
          />
        )}

        </div>
        <PrimaryButton
          label={currentQuestionIndex === questions.length ? "診察結果へ" : "次の質問へ"}
          onClick={handleNextQuestion}
          shouldDisable={shouldDisableButton()}
          className={isButtonFixed ? 'primary-button' : 'button-unfixed'}
        />
    </div>
  );
}

export default QuestionPage;
