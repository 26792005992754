// components/Indicator.jsx
import React from 'react';

function Indicator({ current, total }) {
  const progressPercentage = (current / total) * 100;

  return (
    <div className="indicator axt-indicator">
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
      </div>
      <div className="indicator-text">
        {current}/{total}
      </div>
    </div>
  );
}


export default Indicator;
