import React from 'react';
import PrimaryButton from '../components/PrimaryButtonComponent';
import topLogo from '../assets/img/top-logo.svg';
import { Helmet } from 'react-helmet';

function LandingPage() {
  return (
    <div className="landing-page wrapper">
      <Helmet>
        <title>みんなの防災クリニック</title>
        <meta property="og:title" content="みんなの防災クリニック" />
        <meta property="og:description" content="あなたの防災対策を診察します | みんなの防災クリニック" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className='landing-container'>
        <img src={topLogo} alt="みんなの防災クリニック" />
        <div className="title-container">
          <p className='axt-captionL'>あなたの<span>防災対策</span>を<span>診察します</span></p>
          <h1 className='axt-hero'>みんなの防災クリニック</h1>
        </div>
        <div className="description-container axt-body">
          <p>質問に答えるだけで、防災対策をかんたん診断。
            <br />
            住んでる家も、置いてる家具も、みんな違うから。
            <br />
            あなただけの防災処方箋を。</p>
        </div>
      </div>
      <PrimaryButton label="はじめよう！" to="/questions" />
    </div>
  );
}

export default LandingPage;
